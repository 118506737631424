<template>
  <q-page padding>
    <q-card
      class="col"
    >
      <q-tabs
        align="justify"
        class="bg-grey-3"
        dense
        narrow-indicator
        v-model="topTab"
      >
        <q-tab label="Hints" name="explanation"></q-tab>
        <q-tab label="Theme" name="theme"></q-tab>
        <q-tab label="Script" name="script"></q-tab>
      </q-tabs>
      <q-tab-panels
        animated
        v-model="topTab"
      >
        <q-tab-panel label="Hints" name="explanation">
          <ol>
            <li>
              Write a short script.
            </li>
            <li>
              Surround some interesting words or phrases with a | character at the beginning and end.
              These will get replaced by your friend's answer to questions you write.
            </li>
            <li>
              Write a question for each replacement. You can just write 'Give me a noun'
              or ask a question that gets the kind of answer that would fit.
            </li>
            <li>
              When done, press the 'Open Drill' button in the footer and try it out. This is a URL you can send to
              anyone.
            </li>
            <li>
              Or, press 'Show Code' and get a barcode that others can scan.
            </li>

            <li>
              Example:
              <div class="bg-grey-3 q-pa-md">
                I wish I could |fly|
              </div>
            </li>
            <li>
              <dl>
                <dt>Becomes</dt>
                <dd>I wish I could <b>[Word like fly]</b></dd>
                <!--
                                <dt v-if="sample.missing.length">Defined below but not used:</dt>
                                <dd v-html="sample.missing" v-if="sample.missing.length"></dd>
                -->
              </dl>
            </li>
          </ol>
          <q-card-actions class="row">
            <q-btn
              @click="restart"
              class=" items-center center-block"
              color="primary"
              id="clear_sample"
            >
              Clear the sample<br> and start your own
            </q-btn>
          </q-card-actions>

        </q-tab-panel>

        <q-tab-panel label="Theme" name="theme">
          <q-list>
            <q-item
              class="row"
              tag="label"
              v-bind:key="theme.slug"
              v-for="theme in filteredThemes"

            >
              <img
                :src="'statics/themes/'+theme.slug+'.jpg'"
                class="image_for_picking col-6">

              <q-item-section class="col-5 offset-1">
                <q-radio :val="theme" v-model="currentTheme"/>

                <q-item-label>{{theme.imageCaption}}</q-item-label>


              </q-item-section>
            </q-item>
          </q-list>
        </q-tab-panel>
        <q-tab-panel label="Script" name="script">
          <q-input
            autofocus
            autogrow
            class="bg-grey-3 text-bold"
            id="script"
            label="Script"
            outlined
            ref="script"
            v-model.lazy="script"
          />
          <q-item
            v-if="fieldList.length"
          >
            Create questions to get fun answers:
          </q-item>
          <q-list
            bordered
            padding
            separator
            v-if="fieldList.length"
          >
            <q-item
              v-bind:key="answer"
              v-for="(answer, index) in fieldList"
            >
              <q-item-section
              >
                <q-input

                  :label="'Ask a question to replace \'' + answer + '\' '"
                  v-bind:value="questions[answer]"
                  v-if="index === 0"
                  v-on:input="updateQuestion(answer, $event)"
                ></q-input>
                <q-input
                  :label="'Ask a question to replace \'' + answer + '\' '"
                  v-bind:value="questions[answer]"
                  v-else
                  v-on:input="updateQuestion(answer, $event)"
                ></q-input>

                <q-item-label>
                  <br>
                  <!-- Sample answer:  <b>{{answer}}</b>-->
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>

          devFeedback
          <div v-if="devFeedback">
            {{questions}}
            <br>
            {{yourURLFormat}}
          </div>
        </q-tab-panel>
      </q-tab-panels>

    </q-card>

    <q-footer class="bg-primary" elevated>
      <q-btn
        :to="yourURLFormat"
        color="primary"
        icon="open_in_browser"
        tabindex="-1"
      >
        Open {{currentTheme.title}}
      </q-btn>
    </q-footer>

  </q-page>
</template>

<script>
    import QRCode from 'qrcode'
    import { mapGetters } from 'vuex'
    import { debounce } from 'quasar'

    export default {
        name: 'ComputerCreate',
        created () {
            console.log('debounce created')
            this.updateScript = debounce(this.updateScriptBouncing, 500)
            this.updateQuestion = debounce(this.updateQuestionBouncing, 500)
        },
        computed: {
            filteredThemes () {
                return Array.prototype.slice.call(this.themes).sort((a, b) => a.sort - b.sort)
            },
            currentTheme: {
                get: function () {
                    return this.$store.getters.theme
                },
                set: function (value) {
                    this.updateScript(value)
                },
            },
            script: {
                get: function () {
                    return this.$store.getters.script
                },
                set: function (value) {
                    this.$store.dispatch('script', value)
                },
            },
            ...mapGetters(['themes', 'fieldList', 'yourURLFormat', 'questions']),

        },
        data () {
            return {
                topTab: // 'recordings', //
                    'explanation',
                // 'formatted',
                // questions: {},

                url: '',

                // format: process.env.DEV ? 'grill' : 'grill', // grillSettings
                devFeedback: process.env.DEV, // this.devFeedback// ,
            }
        },
        async mounted () {
            window.jim = window.jim || {}
            window.jim.ComputerCreate = this
            window.jim_DEBUG_FULL = true

            // this.currentTheme = this.themes.find(bySlug => bySlug.slug === (this.$route.params.slug || this.$route.query.theme || 'diary') )
            // this.$store.dispatch('theme', this.$route.params.slug || this.$route.query.theme || 'diary')
            await this.$store.dispatch('syncURL', this.$route)
        },
        methods: {
            updateQuestionBouncing (key, value) {
                let updated = this.questions
                updated[key] = value
                this.$store.dispatch('questions', updated)
            },
            updateScriptBouncing (value) {
                this.$store.dispatch('theme', value.slug)
            },
            restart () {
                this.$store.dispatch('questions', '')
                this.$store.dispatch('script', '')
            },
/*
            restart () {
                this.fields = JSON.parse(JSON.stringify(this.fieldsRestart))
                this.script = ''
                this.topTab = 'script'
            },
            moveUp (offset) {
                let itemIndex = offset
                this.fields[itemIndex].index = offset - 1
                this.fields[itemIndex - 1].index = offset
                this.fields.sort((a, b) => a.index - b.index)
            },
            moveDown (offset) {
                let itemIndex = offset
                this.fields[itemIndex].index = offset + 1
                this.fields[itemIndex + 1].index = offset
                this.fields.sort((a, b) => a.index - b.index)
            },
            remove (index) {
                let itemIndex = index - 1
                this.fields.splice(itemIndex, 1)
                for (let item = itemIndex; item < this.fields.length; item++) {
                    this.fields[item].index = item + 1 // is 1 based
                }
            },
            insert () {
                this.fields.push({
                    index: this.fields.length + 1,
                    question: '',
                    hint: '',
                    answer: '',
                })
            },
*/

            showQR () {
                let can = this.$refs.joinMeQR

                this.url = document.location.origin + '/#' + this.yourURLFormat
                if (this.devFeedback) {
                    this.url = this.url.replace('localhost', '192.168.0.42')
                    console.log('URL: ' + this.url)
                }
                QRCode.toCanvas(can, this.url, function (error) {
                    if (error) {
                        console.log(error)

                    }
                })
            },

        },
    }
</script>

<style>
  .image_for_picking {
    xmax-height: 10vh;
  }
</style>
